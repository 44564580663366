import React from 'react'

const invoiceRoutes = [
    {
        path: '/:siteId/invoice/list',
        exact: true,
        component: React.lazy(() => import('./InvoiceList')),
    },
    {
        path: '/:siteId/invoice/:id',
        component: React.lazy(() => import('./InvoiceDetails')),
    },
    {
        path: '/:siteId/invoice/edit/:id',
        component: React.lazy(() => import('./InvoiceList')),
    },
]

export default invoiceRoutes
