import axios from 'axios'

    const apiUrl = window.location.hostname === "hardware.zerolatencyvr.com" ? "https://api.zerolatencyvr.com/api/v1/" : // Production
    (window.location.hostname === "uat.hardware.zerolatencyvr.com" ? "https://uat.api.zerolatencyvr.com/api/v1/" : // UAT
    (window.location.hostname === "dev.hardware.zerolatencyvr.com" ? "https://dev.api.zerolatencyvr.com/api/v1/" : // DEV
    "https://dev.api.zerolatencyvr.com/api/v1/")) // Local
    // "http://localhost:51449/api/v1/")) // Local

const axiosInstance = axios.create({
    baseURL: apiUrl,
    withCredentials: true
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Something went wrong!'
        )
)

export default axiosInstance
