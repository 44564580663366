export const CHANGE_SITE = 'CHANGE_SITE';
export const SET_SITES = 'SET_SITES';

export const actionCreators = {
    setSites: (sites) => (dispatch) => {
        dispatch({
            type: SET_SITES,
            sites: sites
        });
    },
    changeSite: (site) => (dispatch) => {
        dispatch({
            type: CHANGE_SITE,
            site: site
        });
    }
};