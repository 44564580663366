import React from 'react'

const hardwareRoutes = [
    {
        path: '/:siteId/backpacks',
        exact: true,
        component: React.lazy(() => import('./Backpacks')),
    },
    {
        path: '/:siteId/backpacks/:productId',
        exact: true,
        component: React.lazy(() => import('./ViewProduct')),
    },
    {
        path: '/:siteId/controllers',
        exact: true,
        component: React.lazy(() => import('./Controllers')),
    },
    {
        path: '/:siteId/controllers/:productId',
        exact: true,
        component: React.lazy(() => import('./ViewProduct')),
    },
    {
        path: '/:siteId/headsets',
        exact: true,
        component: React.lazy(() => import('./Headsets')),
    },
    {
        path: '/:siteId/headsets/:productId',
        exact: true,
        component: React.lazy(() => import('./ViewProduct')),
    },
    {
        path: '/:siteId/game-servers',
        exact: true,
        component: React.lazy(() => import('./GameServers')),
    },
    {
        path: '/:siteId/game-servers/:productId',
        exact: true,
        component: React.lazy(() => import('./ViewProduct')),
    },
    {
        path: '/:siteId/streaming-servers',
        exact: true,
        component: React.lazy(() => import('./StreamingServers')),
    },
    {
        path: '/:siteId/streaming-servers/:productId',
        exact: true,
        component: React.lazy(() => import('./ViewProduct')),
    },
    {
        path: '/:siteId/wearable-sets',
        exact: true,
        component: React.lazy(() => import('./WearableSets')),
    },
    {
        path: '/:siteId/wearable-sets/:wearableSetId',
        exact: true,
        component: React.lazy(() => import('./WearableSet')),
    },
    {
        path: '/:siteId/wearable-sets/:wearableSetId/status',
        exact: true,
        component: React.lazy(() => import('./WearableSetStatus')),
    },
]

export default hardwareRoutes
