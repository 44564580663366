import React from 'react'

const faultsRoutes = [
    {
        path: '/',
        exact: true,
        component: React.lazy(() => import('./Dashboard')),
    },
    {
        path: '/:siteId/home',
        exact: true,
        component: React.lazy(() => import('./Dashboard')),
    },
]

export default faultsRoutes
