import { SET_SITES, CHANGE_SITE, actionCreators as SitesActions } from '../actions/SitesActions'

const undefinedState = {
    currentSite: undefined,
    list: []
};

export const reducer = (state, incomingAction) => {
    const action = incomingAction;
    switch (action.type) {
        case SET_SITES: {
            return {
                ...state,
                list: action.sites
            };
        }
        case CHANGE_SITE: {
            return {
                ...state,
                currentSite: action.site
            };
        }
        default:
            if (state === undefined) return undefinedState;
            return state;
    }
};

export default reducer;