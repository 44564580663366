import '../fake-db'
import React from 'react'
import { Provider } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { Store, saveState } from './redux/Store'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import throttle from 'lodash/throttle'

const App = () => {
    Store.subscribe(
        throttle(() => {
            const state = Store.getState();
            saveState({
                i18n: {
                    currentLocale: state.i18n.currentLocale,
                    fallbackLocale: state.i18n.fallbackLocale,
                    rtl: state.i18n.rtl,
                    momentLocale: state.i18n.momentLocale,
                }
            });
        }, 1000)
    )
    
    return (
        <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
                <SettingsProvider>
                    <MatxTheme>
                        <GlobalCss />
                        <Router history={history}>
                                <MatxSuspense>
                                <Switch>
                                    {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                    {sessionRoutes.map((item, i) => (
                                        <Route
                                            key={i}
                                            path={item.path}
                                            component={item.component}
                                        />
                                    ))}
                                    {/* AUTH PROTECTED DASHBOARD PAGES */}
                                    <Route  path="/:siteId?" component={MatxLayout} />{' '}
                                </Switch>
                            </MatxSuspense>
                        </Router>
                    </MatxTheme>
                </SettingsProvider>
            </Provider>
        </AppContext.Provider>
    )
}

export default App
