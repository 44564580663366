import { SET_USER, LOGOUT, actionCreators as UserActions } from '../actions/UserActions'

const undefinedState = {
    userId: undefined,
    name: undefined,
    email: undefined,
    avatar: undefined
};

export const reducer = (state, incomingAction) => {
    const action = incomingAction;
    switch (action.type) {
        case SET_USER: {
            return {
                ...state,
                userId: action.user.id,
                name: action.user.name,
                email: action.user.email,
                avatar: action.user.avatar
            };
        }
        case LOGOUT: {
            return undefinedState;
        }
        default:
            if (state === undefined) return undefinedState;
            return state;
    }
};

export default reducer;