import i18n from '../../../i18n';
import { languageList } from '../../services/lang';
import { setLanguage } from '../../helpers/i18n';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const actionCreators = {
    changeLocale: (locale) => (dispatch) => {
        const lang = languageList.find((lng) => lng.code === locale);

        if (lang === undefined || i18n.language === lang.code) return null;

        setLanguage(lang);

        dispatch({
            type: CHANGE_LOCALE,
            locale: lang?.code ?? 'en-AU',
            rtl: lang?.rtl === true,
            momentLocale: lang?.momentLocale ?? 'en-AU',
        });
    },
    setLocale: (locale) => (dispatch) => {
        const lang = languageList.find((lng) => lng.code === locale);

        if (lang === undefined || i18n.language === lang.code) return null;

        setLanguage(lang);
    }
};