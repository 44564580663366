import React from 'react'

const dragAndDropRoute = [
    {
        path: '/:siteId/others/drag-and-drop',
        component: React.lazy(() => import('./AppDragAndDrop')),
    },
]

export default dragAndDropRoute
