// export interface ZLLanguage {
//     code: string;
//     displayText: string;
//     rtl: boolean;
//     momentLocale: string;
// }

export const languageList = [
    { code: 'en-US', displayText: 'English (US)', rtl: false, momentLocale: 'en' }, //en-US first, i18n defaults to en-US
    { code: 'en-AU', displayText: 'English (AU)', rtl: false, momentLocale: 'en-au' },
    { code: 'ar-SA', displayText: 'العَرَبِيَّة‎', rtl: true, momentLocale: 'ar-sa' },
    { code: 'ja-JP', displayText: '日本語', rtl: false, momentLocale: 'ja' },
    { code: 'ca-ES', displayText: 'Català', rtl: false, momentLocale: 'ca' },
    { code: 'de-DE', displayText: 'Deutsch', rtl: false, momentLocale: 'de' },
    { code: 'es-ES', displayText: 'Español', rtl: false, momentLocale: 'es' },
    { code: 'fr-CA', displayText: 'Français Canadien', rtl: false, momentLocale: 'fr-ca' },
    { code: 'fr-FR', displayText: 'Français', rtl: false, momentLocale: 'fr' },
    { code: 'it-IT', displayText: 'Italiano', rtl: false, momentLocale: 'it' },
    { code: 'nl-NL', displayText: 'Dutch', rtl: false, momentLocale: 'nl' },
    { code: 'pt-PT', displayText: 'Português', rtl: false, momentLocale: 'pt' },
    // { code: 'th-TH', displayText: 'ภาษาไทย', rtl: false, momentLocale: 'th' },
    // { code: 'zh-CN', displayText: '中文简体', rtl: false, momentLocale: 'zh-cn' },
    // { code: 'zh-TW', displayText: '中文繁體', rtl: false, momentLocale: 'zh-tw' },
];
