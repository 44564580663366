import { CHANGE_LOCALE, actionCreators as LanguageActions } from '../actions/I18NActions'
import { languageList } from '../../services/lang';

const detectedLanguage = window.navigator.language;
const storedCode = localStorage.getItem('i18nextLng');
const storedLanguage = languageList.find((language) => language.code.startsWith(storedCode ?? detectedLanguage));

const undefinedState = {
    currentLocale: storedLanguage?.code ?? 'en-AU',
    fallbackLocale: storedLanguage?.code ?? 'en-AU',
    rtl: storedLanguage?.rtl ?? false,
    momentLocale: storedLanguage?.momentLocale ?? 'en-au',
};

export const reducer = (state, incomingAction) => {
    const action = incomingAction;
    switch (action.type) {
        case CHANGE_LOCALE: {
            return {
                currentLocale: action.locale,
                fallbackLocale:
                    state?.fallbackLocale === undefined || state?.fallbackLocale === '' ? action.locale : '',
                rtl: action.rtl,
                momentLocale: action.momentLocale,
            };
        }
        default:
            if (state === undefined) return undefinedState;
            return state;
    }
};

export default reducer;