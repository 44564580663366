import { combineReducers } from 'redux'
import NotificationReducer from './NotificationReducer'
import NavigationReducer from './NavigationReducer'
import I18NReducer from './I18NReducer'
import SitesReducer from './SitesReducer'
import UserReducer from './UserReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    i18n: I18NReducer,
    sites: SitesReducer,
    user: UserReducer
})

export default RootReducer
