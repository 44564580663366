import { Logout } from "app/auth/zlauth";

export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';

export const actionCreators = {
    setUser: (user) => (dispatch) => {
        dispatch({
            type: SET_USER,
            user: user
        });
    },
    logout: () => (dispatch) => {
        Logout();
        dispatch({
            type: LOGOUT
        });
    }
};