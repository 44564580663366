import React, { Fragment, useEffect, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import useSettings from 'app/hooks/useSettings'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    menuButton: {
        display: 'inline-block',
        color: palette.text.primary,
        '& div:hover': {
            backgroundColor: palette.action.hover,
        },
    },
}))

const MatxMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const children = React.Children.toArray(props.children)
    let { shouldCloseOnItemClick = true, horizontalPosition = 'left' } = props
    const { settings } = useSettings()
    const classes = useStyles()
    const [upPressed, setUpPressed] = useState(false);
    const [downPressed, setDownPressed] = useState(false);
    const [enterPressed, setEnterPressed] = useState(false);
    const open = !!anchorEl;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if(props.onOpen) props.onOpen();
    }

    const handleClose = () => {
        setAnchorEl(null)
        if(props.onClose) props.onClose();
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        // Remove event listeners on cleanup
        return () => {
          window.removeEventListener("keydown", downHandler);
        };
      }, []); // Empty array ensures that effect is only run on mount and unmount

    const downHandler = ({ key }) => {
        if (key === "ArrowDown") {
            setDownPressed(true);
        } else if (key === "ArrowUp") {
            setUpPressed(true);
        } else if (key === "Enter") {
            setEnterPressed(true);
        }
    }

    useEffect(() => {
        if(upPressed) {
            setUpPressed(false);
            if(open) {
                if(props.onItemUp) props.onItemUp();
            }
        }    
    }, [upPressed]);

    useEffect(() => {
        if(downPressed) {
            setDownPressed(false);
            if(open) {
                if(props.onItemDown) props.onItemDown();
            }
        }    
    }, [downPressed]);

    useEffect(() => {
        if(enterPressed) {
            setEnterPressed(false);
            if(open) {
                if(props.onItemEnter) props.onItemEnter();
                handleClose();
            }
        }    
    }, [enterPressed]);

    return (
        <Fragment>
            <div className={classes.menuButton} onClick={handleClick}>
                {props.menuButton}
            </div>
            <ThemeProvider theme={settings.themes[settings.activeTheme]}>
                <Menu
                    elevation={8}
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: horizontalPosition,
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: horizontalPosition,
                    }}
                >
                    {children.map((child, index) => (
                        <div
                            onClick={
                                shouldCloseOnItemClick && (!props.ignoreFirstItem || index > 0) ? handleClose : () => {}
                            }
                            key={index}
                        >
                            {child}
                        </div>
                    ))}
                </Menu>
            </ThemeProvider>
        </Fragment>
    )
}

export default MatxMenu
